<template>
  <register-container>
    <template slot="header"> ({{ errorCode }}) {{ this.$t(`notFound.${errorCode}`) }}</template>
  </register-container>
</template>

<script>
import RegisterContainer from '../components/RegisterContainer.vue';

export default {
  components: { RegisterContainer },
  name: 'RegisterNotFound',
  data: () => ({}),
  computed: {
    errorCode() {
      return this.$route.query.errorCode;
    },
  },
};
</script>
